







































































import Vue from 'vue';

const testStatistics: {
  total: { mean: number; stDev: number };
  sjt: { mean: number; stDev: number }
} = {
  total: {
    mean: 2538,
    stDev: 360,
  },
  sjt: {
    mean: 581,
    stDev: 72,
  },
};

export default Vue.extend({
  name: 'Percentile',
  data: (): {
     scores: { qr: number; ar: number; sj: number; dm: number; vr: number }
  } => ({
    scores: {
      qr: 300,
      ar: 300,
      dm: 300,
      vr: 300,
      sj: 300,
    },
  }),
  computed: {
    total(): number {
      return this.scores.qr + this.scores.ar + this.scores.dm + this.scores.vr;
    },
    percentile(): number {
      const z = this.zScore(this.total, testStatistics.total.mean, testStatistics.total.stDev);
      return this.getPercentile(z);
    },
    sjtPercentile(): number {
      const z = this.zScore(this.scores.sj, testStatistics.sjt.mean, testStatistics.sjt.stDev);
      return this.getPercentile(z);
    },
  },
  methods: {
    inRange(min: number, max: number) {
      return (v: unknown) => (
        (v !== undefined && v !== null && Number(v) >= min && Number(v) <= max)
        || `This value must be between ${min} and ${max}.`
      );
    },
    getPercentile(z: number): number {
      let loopStop;
      let term;
      let k;
      let sum;
      let factK;

      if (z < -6.5) sum = 0;
      if (z > 6.5) sum = 1;
      else {
        factK = 1;
        sum = 0;
        term = 1;
        k = 0;
        loopStop = Math.exp(-23);
        while (Math.abs(term) > loopStop) {
          // Fucky number sorcery
          term = (0.3989422804 * ((-1) ** k) * (z ** k) * (z ** (k + 1)))
            / (factK * (2 * k + 1) * (2 ** k));
          sum += term;
          k += 1;
          factK *= k;
        }
        sum += 0.5;
      }

      return sum;
    },
    zScore(value: number, mean: number, stDev: number): number {
      if (stDev === 0) return 0;
      return (value - mean) / stDev;
    },
  },
});
