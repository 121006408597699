































































































import Vue from 'vue';

export default Vue.extend({
  name: 'Route',
  props: {
    route: Object,
  },
  data: () => ({
    mode: 0,
    fieldDialog: false,
    fieldTarget: 0,
    responseDialog: false,
    responseTarget: '',
    responseKey: '',
    responseValue: '',
  }),
  mounted() {
    if (this.route.listId !== undefined) {
      this.mode = 0;
    } else {
      this.mode = 1;
    }
  },
  computed: {
    url(): string {
      return `https://calculator.nie.edu/subscribe/${encodeURIComponent(this.route.name.trim().replaceAll(/\s+/gmi, '-').toLowerCase())}`;
    },
    targetedField: {
      get(): string {
        return this.route.fields[this.fieldTarget];
      },
      set(value: string): void {
        const index = this.route.fields.indexOf(value);
        if (index >= 0 && (index !== this.fieldTarget
          || this.route.fields.filter((v: string) => v === value) > 1)) {
          this.targetedField = `${value} copy`;
        } else {
          Vue.set(this.route.fields, this.fieldTarget, value);
        }
      },
    },
  },
  methods: {
    copyUrl() {
      const component = this.$refs.urlField as Vue;
      const el = component.$el.querySelector('input');
      if (el === null) return;
      el.select();
      el.setSelectionRange(0, 9999);
      navigator.clipboard.writeText(el.value);
    },
    deleteField(idx: number) {
      if (this.route.fields.length === 1) {
        Vue.delete(this.route, 'fields');
      } else {
        this.route.fields.splice(idx, 1);
      }
    },
    resetFields() {
      Vue.set(this.route, 'fields', ['New Field']);
    },
    addListId() {
      const existingKeys = Object.keys(this.route.listIds);
      let newKey = 'New Response';
      while (existingKeys.includes(newKey)) {
        newKey += ' copy';
      }
      Vue.set(this.route.listIds, newKey, '');
      if (!this.route.fields) Vue.set(this.route, 'fields', []);
      this.fieldTarget = this.route.fields.length - 1;
      this.targetedField = 'New Field';
    },
    saveResponse() {
      if (this.responseTarget !== '' && this.responseKey !== this.responseTarget) {
        Vue.delete(this.route.listIds, this.responseTarget);
      }
      Vue.set(this.route.listIds, this.responseKey, this.responseValue);
      this.responseDialog = false;
      this.responseTarget = '';
      this.responseValue = '';
      this.responseKey = '';
    },
    editResponse(listId: string, key: string) {
      this.responseValue = listId;
      this.responseTarget = key;
      this.responseKey = key;
      this.responseDialog = true;
    },
    deleteResponse(key: string) {
      console.log(key);
      Vue.delete(this.route.listIds, key);
    },
  },
  watch: {
    mode() {
      if (this.mode === 0) {
        Vue.set(this.route, 'listId', this.route.listId ?? '');
        Vue.delete(this.route, 'listIds');
        Vue.delete(this.route, 'listField');
      } else {
        Vue.delete(this.route, 'listId');
        Vue.set(this.route, 'listIds', this.route.listIds ?? {});
        Vue.set(this.route, 'listField', this.route.listField ?? '');
      }
    },
  },
});
