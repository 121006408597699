
































































import Vue, { PropType } from 'vue';
import {
  collection, documentId, Firestore, getDocs, query, where,
} from 'firebase/firestore';
import { Functions, httpsCallable } from 'firebase/functions';

export default Vue.extend({
  name: 'Subscribe',
  props: {
    db: {
      type: Object as PropType<Firestore>,
      required: true,
    },
    functions: {
      type: Object as PropType<Functions>,
      required: true,
    },
  },
  data: (): {
    routeConfig: {
      routes: {
        name: string,
        fields?: string[],
        listField?: string,
        listIds?: Record<string, string>,
        listId?: string,
      }[] | null
    },
    response: Record<string, string>,
    target: string,
    formValid: boolean,
    email: string,
    result: string | null,
    loading: boolean
  } => ({
    routeConfig: {
      routes: null,
    },
    response: {},
    target: '',
    formValid: false,
    email: '',
    result: null,
    loading: false,
  }),
  computed: {
    dialog: {
      get(): boolean {
        return this.result !== null;
      },
      set(v: unknown): void {
        if (!v) {
          this.result = null;
        }
      },
    },
    routeId(): string {
      return this.$route.params.id;
    },
    route(): {
      name: string,
      fields?: string[],
      listField?: string,
      listIds?: Record<string, string>,
      listId?: string,
    } | null {
      return this.routeConfig.routes?.find((route) => route.name.toLowerCase().trim().replaceAll(/\s+/gmi, '-')
        === this.routeId.trim().toLowerCase()) ?? null;
    },
    routeMultiMode(): boolean {
      return !!this.route?.listField;
    },
    routeTargets(): {text: string, value: string}[] {
      const targets: { text: string; value: string; }[] = [];
      Object.keys(this.route?.listIds ?? {}).forEach((key) => {
        targets.push({ text: key, value: (this.route?.listIds ?? {})[key] ?? '' });
      });
      return targets;
    },
  },
  mounted() {
    // Load route config
    this.fetchRouteConfig();
  },
  methods: {
    async fetchRouteConfig(): Promise<void> {
      const q = query(collection(this.db, 'routes'), where(documentId(), '==', 'active'));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((document) => {
        this.routeConfig = document.data() as {
          routes: {
            name: string,
            fields?: string[],
            listField?: string,
            listIds?: Record<string, string>,
            listId?: string,
          }[]
        };
      });
    },
    goHome(): void {
      window.location.assign('https://nie.edu.au');
    },
    subscribe(): void {
      const subFunc = httpsCallable<
        {email: string, data: Record<string, string>, listId: string}, string
        >(this.functions, 'subscribe');
      const data: Record<string, string> = {};
      Object.keys(this.response).forEach((resKey) => {
        data[resKey.replaceAll(' ', '')] = this.response[resKey];
      });
      this.loading = true;
      subFunc({ email: this.email, data, listId: (this.target || this.route?.listId) ?? '' })
        .then((res) => {
          this.result = res.data;
          this.loading = false;
        });
    },
  },
});
