























import Vue from 'vue';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { AppCheck, initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { Functions, getFunctions } from 'firebase/functions';
import {
  Auth, getAuth, onAuthStateChanged, User,
} from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';

export default Vue.extend({
  name: 'App',

  data(): {
    app: FirebaseApp | null,
    functions: Functions | null,
    appCheck: AppCheck | null,
    auth: Auth | null,
    user: User | null,
    db: Firestore | null,
    } {
    return {
      app: null,
      functions: null,
      appCheck: null,
      auth: null,
      user: null,
      db: null,
    };
  },

  mounted() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.debug = this;

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.$vuetify.theme.dark = true;
    }

    const app = initializeApp({
      apiKey: 'AIzaSyATzoi8HVkYTR1ADxpd_NphH361M3-36MI',
      authDomain: 'nie-calculators.firebaseapp.com',
      projectId: 'nie-calculators',
      storageBucket: 'nie-calculators.appspot.com',
      messagingSenderId: '793359387192',
      appId: '1:793359387192:web:96aee163fe5a1085d974cd',
    });

    const functions = getFunctions(app);

    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider('6LfwF3kcAAAAANBp6HuOSafFQo9nqklkhfMvRpe5'),
      isTokenAutoRefreshEnabled: true,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.app = app;

    const auth = getAuth();

    // signOut(auth);

    onAuthStateChanged(auth, (user) => {
      this.user = user;
    });

    this.db = getFirestore();
    this.app = app;
    this.auth = auth;
    this.functions = functions;
    this.appCheck = appCheck;
  },
});
