import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import StudyPrep from '@/views/StudyPrep.vue';
import Percentile from '@/views/Percentile.vue';
import Admin from '@/views/Admin.vue';
import Home from '@/views/Home.vue';
import Subscribe from '@/views/Subscribe.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/studyprep',
    name: 'Study Prep',
    component: StudyPrep,
  },
  {
    path: '/percentile',
    name: 'Percentile',
    component: Percentile,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/subscribe/:id',
    name: 'Subscribe',
    component: Subscribe,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
