





















































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as d3 from 'd3';
import * as stats from 'simple-statistics';
// noinspection TypeScriptCheckImport
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import Vue from 'vue';
import {
  collection, documentId, getDocs, query, where,
} from 'firebase/firestore';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection TypeScriptCheckImport
import * as jStat from 'jstat';

const zScoreToQuantile = (z: number) => {
  // z == number of standard deviations from the mean

  // if z is greater than 6.5 standard deviations from the mean
  // the number of significant digits will be outside of a reasonable
  // range
  if (z < -6.5) return 0.0;
  if (z > 6.5) return 1.0;

  let factK = 1;
  let sum = 0;
  let term = 1;
  let k = 0;
  const loopStop = Math.exp(-23);
  while (Math.abs(term) > loopStop) {
    // eslint-disable-next-line no-restricted-properties,no-mixed-operators,max-len
    term = 0.3989422804 * Math.pow(-1, k) * Math.pow(z, k) / (2 * k + 1) / Math.pow(2, k) * Math.pow(z, k + 1) / factK;
    sum += term;
    k += 1;
    factK *= k;
  }
  sum += 0.5;

  return sum;
};

const classes = [
  500,
  1500,
  2500,
  4000,
  6000,
  // 10000,
];

type SubmissionClass = 0 | 1 | 2 | 3 | 4 | 5;

const getClass = (questions: number): SubmissionClass => {
  let i = 0;
  while (classes[i] < questions && classes.length > i) {
    i += 1;
  }
  return i as SubmissionClass;
};

type StatsItem = { sd: number, mean: number };
type StatsGroup = {
  0: StatsItem,
  1: StatsItem,
  2: StatsItem,
  3: StatsItem,
  4: StatsItem,
  5: StatsItem
};

export default Vue.extend({
  name: 'StudyPrep',
  props: {
    app: {
      type: Object,
      required: true,
    },
    functions: {
      type: Object,
      required: true,
    },
    db: {
      type: Object,
      required: true,
    },
  },
  data(): {
    required: (v: unknown) => boolean | string,
    inRange: (min: number, max: number) => ((v: unknown) => boolean | string),
    scores: {
      vr: number,
      dm: number,
      qr: number,
      ar: number,
      sj: number,
      prep: number,
    },
    year: number | null,
    state: 'NSW' | 'ACT' | 'SA' | 'TAS' | 'VIC' | 'QLD' | 'WA' | 'NT' | 'NZ' | 'Other' | null,
    otherLocation: string | null,
    email: string | null,
    loading: boolean,
    scoresValid: boolean,
    detailsValid: boolean,
    diagramMode: 0 | 1 | 2 | 3 | 4 | 5,
    data: {
      vr: StatsGroup,
      dm: StatsGroup,
      qr: StatsGroup,
      ar: StatsGroup,
      sj: StatsGroup,
      total: StatsGroup,
    } | null,
    groupLower: string,
    groupMain: string,
    groupHigher: string,
    } {
    return {
      inRange: (min: number, max: number) => (
        (v: unknown) => (
          (v !== undefined && v !== null && Number(v) >= min && Number(v) <= max)
          || `This value must be between ${min} and ${max}.`
        )
      ),
      required: ((v: unknown) => (!!v || 'This value is required')),
      email: null,
      otherLocation: null,
      state: null,
      year: null,
      scores: {
        vr: 300,
        dm: 300,
        qr: 300,
        ar: 300,
        sj: 300,
        prep: 0,
      },
      loading: false,
      scoresValid: false,
      detailsValid: false,
      diagramMode: 0,
      data: null,
      groupLower: '',
      groupMain: '',
      groupHigher: '',
    };
  },
  computed: {
    upcomingYears(): number[] {
      const years: number[] = [];
      const current = new Date().getFullYear();
      for (let i = 0; i < 5; i += 1) {
        years.push(current + i);
      }
      return years;
    },
  },
  methods: {
    submitScores(): void {
      const data: {
        scores: {
          vr: number,
          dm: number,
          qr: number,
          ar: number,
          sj: number,
          prep: number,
        },
        year: number | null,
        state: 'NSW' | 'ACT' | 'SA' | 'TAS' | 'VIC' | 'QLD' | 'WA' | 'NT' | 'NZ' | 'Other' | null,
        otherLocation: string | null,
        email: string | null,
      } = {
        scores: this.scores,
        email: this.email,
        otherLocation: this.otherLocation,
        state: this.state,
        year: this.year,
      };
      if (this.scoresValid && this.detailsValid) {
        const submitData = httpsCallable(this.functions, 'submitData');
        this.loading = true;
        submitData(data)
          .then((res: HttpsCallableResult) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const resData: any = res.data;
            if (resData?.status === 'success') {
              this.getStatistics();
            } else {
              // eslint-disable-next-line no-console
              console.log(resData.code);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async getStatistics() {
      const q = query(collection(this.db, 'submissions'), where(documentId(), '==', 'aggregate'));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((aggregate) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.data = aggregate.data();
        this.$nextTick(() => {
          if (!this.data) return;
          const categories: ('total' | 'vr' | 'dm' | 'qr' | 'ar' | 'sj')[] = ['total', 'vr', 'dm', 'qr', 'ar', 'sj'];
          const category = categories[this.diagramMode];
          const userScore = category === 'total' ? this.scores.ar + this.scores.vr + this.scores.dm + this.scores.qr : this.scores[category];
          const classNumber = getClass(this.scores.prep);
          const zScore = stats.zScore(
            userScore,
            this.data[category][classNumber].mean,
            this.data[category][classNumber].sd,
          );
          const quantile = zScoreToQuantile(zScore);
          const percentileText = (Math.round((quantile * 1000)) / 10).toFixed(1);

          this.drawCharts(category, classNumber, userScore, percentileText, category === 'total' ? 1200 : 300, category === 'total' ? 3600 : 900);
        });
      });
    },
    drawCharts(
      category: 'total' | 'vr' | 'dm' | 'qr' | 'ar' | 'sj',
      classNumber: SubmissionClass,
      currentScore: number,
      percentile: string,
      min: number,
      max: number,
    ) {
      if (!this.data) return;

      const c = [
        0,
        500,
        1500,
        2500,
        4000,
        6000,
        10000,
      ];

      let j = c.length - 1;
      while (c[j] >= this.scores.prep && j > 0) {
        j -= 1;
      }
      const classB = j;
      const classA = (classB === 0 ? 0 : classB - 1);
      const classC = (classB === 6 ? 6 : classB + 1);
      const classD = (classC === 6 ? 6 : classC + 1);
      this.groupLower = `${c[classA]}-${c[classB]} Qs`;
      this.groupMain = `${c[classB]}-${(classB === 6 ? '∞' : c[classC])} Qs`;
      this.groupHigher = `${c[classC]}-${(classC === 6 ? '∞' : c[classD])} Qs`;

      const randomNormalDist = (mean: number, sd: number): ({ q: number, p: number })[] => {
        const data = [];
        for (let i = mean - 4 * sd; i < mean + 4 * sd; i += 1) {
          const q = i;
          const p = jStat.normal.pdf(i, mean, sd);
          const arr = {
            q,
            p,
          };
          data.push(arr);
        }
        return data;
      };

      const margin = {
        top: 20, right: 30, bottom: 30, left: 40,
      };

      const containerWidth = document.getElementById('percentiles')?.clientWidth ?? 170;

      const width = containerWidth - 100 - margin.left - margin.right;
      const height = Math.floor((containerWidth - 100) / 3) - margin.top - margin.bottom;

      let arrayLower: ({ q: number, p: number })[] = [];
      if (classNumber > 0) {
        const lowerNumber = classNumber - 1 as SubmissionClass;
        arrayLower = randomNormalDist(
          this.data[category][lowerNumber].mean,
          this.data[category][lowerNumber].sd,
        );
      }
      const arrayMain = randomNormalDist(
        this.data[category][classNumber].mean,
        this.data[category][classNumber].sd,
      );
      let arrayHigher: ({ q: number, p: number })[] = [];
      if (classNumber < 5) {
        const higherNumber = classNumber + 1 as SubmissionClass;
        arrayHigher = randomNormalDist(
          this.data[category][higherNumber].mean,
          this.data[category][higherNumber].sd,
        );
      }
      const x = d3.scaleLinear()
        .rangeRound([0, width]);

      // Min q
      // const minLower = d3.min(arrayLower, (d: { q: number; p: number; }) => d.q);
      // const minMain = d3.min(arrayMain, (d: { q: number; p: number; }) => d.q);
      // const minHigher = d3.min(arrayHigher, (d: { q: number; p: number; }) => d.q);

      // Max q
      let maxLower = d3.max(arrayLower, (d: { q: number; p: number; }) => d.q) as number;
      let maxMain = d3.max(arrayMain, (d: { q: number; p: number; }) => d.q) as number;
      let maxHigher = d3.max(arrayHigher, (d: { q: number; p: number; }) => d.q) as number;

      // Max p
      maxLower = d3.max(arrayLower, (d: { q: number; p: number; }) => d.p) as number;
      maxMain = d3.max(arrayMain, (d: { q: number; p: number; }) => d.p) as number;
      maxHigher = d3.max(arrayMain, (d: { q: number; p: number; }) => d.p) as number;
      const maxP = d3.max([maxLower, maxMain, maxHigher]) as number;

      x.domain([min, max]).nice();

      const y = d3.scaleLinear()
        .domain([0, maxP])
        .range([height, 50]);

      const el = document.getElementById('distributionsChart');
      if (el) el.remove();

      const svg = d3.select('#percentiles').insert('svg', '#legend')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .attr('id', 'distributionsChart')
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      svg.append('g')
        .attr('class', 'x axis')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x));

      // var gY = svg.append("g")
      //            .attr("class", "y axis")
      //            .call(d3.axisLeft(y));

      const line = d3.line<{q: number; p: number}>()
        .x((d: {q: number; p: number}) => x(d.q))
        .y((d: {q: number; p: number}) => y(d.p));

      svg.append('path')
        .datum(arrayLower)
        .attr('class', 'line')
        .attr('d', line)
        .style('fill', this.$vuetify.theme.dark ? '#ff6961' : '#773333')
        .style('opacity', '0.3');

      svg.append('path')
        .datum(arrayHigher)
        .attr('class', 'line')
        .attr('d', line)
        .style('fill', this.$vuetify.theme.dark ? '#77dd77' : '#2b8c2b')
        .style('opacity', '0.3');

      svg.append('path')
        .datum(arrayMain)
        .attr('class', 'line')
        .attr('d', line)
        .style('fill', this.$vuetify.theme.dark ? '#3399FF' : '#0066CC')
        .style('opacity', '0.5');

      const xMin = x.domain()[0];
      const xMax = x.domain()[1];

      svg.append('line')
        .attr('x1', ((currentScore - xMin) / (xMax - xMin)) * (width))
        .attr('y1', 0)
        .attr('x2', ((currentScore - xMin) / (xMax - xMin)) * (width))
        .attr('y2', height)
        .style('stroke-width', 2)
        .style('stroke', this.$vuetify.theme.dark ? 'white' : 'black')
        .style('fill', 'none');

      svg.append('text')
        .attr('x', ((currentScore - xMin) / (xMax - xMin)) * (width) + 3)
        .attr('y', 15)
        .attr('font-family', 'sans-serif')
        .attr('font-size', '18px')
        .attr('font-weight', 'bold')
        .attr('fill', this.$vuetify.theme.dark ? 'white' : 'black')
        .text(`${percentile}%`);

      svg.append('text')
        .attr('x', ((currentScore - xMin) / (xMax - xMin)) * (width) + 3)
        .attr('y', 35)
        .attr('font-family', 'sans-serif')
        .attr('font-size', '18px')
        .attr('font-weight', 'bold')
        .attr('fill', this.$vuetify.theme.dark ? 'white' : 'black')
        .text(currentScore);
    },
  },
  watch: {
    diagramMode(val) {
      if (this.data === null) return;
      const categories: ('total' | 'vr' | 'dm' | 'qr' | 'ar' | 'sj')[] = ['total', 'vr', 'dm', 'qr', 'ar', 'sj'];
      const category = categories[val];
      const userScore = category === 'total' ? this.scores.ar + this.scores.vr + this.scores.dm + this.scores.qr : this.scores[category];
      const classNumber = getClass(this.scores.prep);
      const zScore = stats.zScore(
        userScore,
        this.data[category][classNumber].mean,
        this.data[category][classNumber].sd,
      );
      const quantile = zScoreToQuantile(zScore);
      const percentileText = (Math.round((quantile * 1000)) / 10).toFixed(1);

      this.drawCharts(category, classNumber, userScore, percentileText, category === 'total' ? 1200 : 300, category === 'total' ? 3600 : 900);
    },
  },
});
