



















































































































/* eslint-disable no-void */
import Vue, { PropType } from 'vue';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  documentId,
  Firestore,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import Papa from 'papaparse';
import { cloneDeep } from 'lodash';
import Route from '@/components/Route.vue';

const validUIDs = [
  'DPDkIMZUBPOsPIrlEXyd4pZp5Gv1',
  'e6u3pDjO9EVHCt1qg6cRtanuxye2',
];

export default Vue.extend({
  name: 'Admin',
  components: { Route },
  data: (): {
    fileUpload: File | null
    validUIDs: string[],
    uploadDialog: boolean,
    updatedDialog: boolean,
    newRouteDialog: boolean,
    newRouteName: string,
    newRouteValid: boolean,
    loading: boolean,
    routeConfig: {
      routes: {
        name: string,
        fields?: string[],
        listField?: string,
        listIds?: Record<string, string>,
        listId?: string,
      }[] | null
    }
  } => ({
    validUIDs,
    fileUpload: null,
    uploadDialog: false,
    updatedDialog: false,
    newRouteDialog: false,
    newRouteName: '',
    newRouteValid: false,
    loading: false,
    routeConfig: {
      routes: null,
    },
  }),
  props: {
    auth: Object,
    user: Object,
    db: {
      type: Object as PropType<Firestore>,
      required: true,
    },
  },
  mounted() {
    // Load route config
    if (this.user) {
      void this.fetchRouteConfig();
    }
  },
  methods: {
    async updateRouteConfig() {
      if (this.routeConfig.routes === null) return;
      this.loading = true;
      await setDoc(doc(this.db, 'routes', 'active'), this.routeConfig);
      this.loading = false;
      this.updatedDialog = true;
    },
    async fetchRouteConfig() {
      const q = query(collection(this.db, 'routes'), where(documentId(), '==', 'active'));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((document) => {
        this.routeConfig = document.data() as {
          routes: {
            name: string,
            fields?: string[],
            listField?: string,
            listIds?: Record<string, string>,
            listId?: string,
          }[]
        };
      });
    },
    signIn() {
      signInWithPopup(this.auth, new GoogleAuthProvider());
    },
    async download() {
      const q = query(collection(this.db, 'submissions'), where(documentId(), '!=', 'aggregate'));
      const querySnapshot = await getDocs(q);
      const data: DocumentData[] = [];
      querySnapshot.forEach((document) => {
        const newRecord = document.data();
        Object.assign(newRecord, newRecord.scores);
        delete newRecord.scores;
        data.push(newRecord);
      });
      let csvString = '';
      const keys = ['email', 'state', 'otherLocation', 'year', 'prep', 'ar', 'sj', 'qr', 'dm', 'vr'];
      data.forEach((record) => {
        if (csvString.length === 0) {
          keys.forEach((key) => {
            csvString += `${key},`;
          });
          csvString = csvString.substr(0, csvString.length - 1);
          csvString += '\n';
        }
        // Object.values(record).forEach((value) => {
        //   csvString += `${value},`;
        // });
        keys.forEach((key) => {
          csvString += `${record[key] ?? ''},`;
        });
        csvString = csvString.substr(0, csvString.length - 1);
        csvString += '\n';
      });
      const file = new File([csvString], 'data.csv', { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(file);
      link.setAttribute('href', url);
      link.setAttribute('download', 'data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async upload() {
      if (this.fileUpload) {
        Papa.parse<{
          ar?: number,
          dm?: number,
          qr?: number,
          sj?: number,
          vr?: number,
          email: string,
          prep?: number,
          scores: Record<string, undefined | number>
        }>(this.fileUpload, {
          dynamicTyping: true,
          header: true,
          skipEmptyLines: 'greedy',
          complete: async (results) => {
            Promise.all(results.data.map(async (data) => {
              const newData = cloneDeep(data);
              const {
                ar, dm, qr, sj, vr, prep,
              } = newData;
              newData.scores = {
                ar, dm, qr, sj, vr, prep,
              };
              delete newData.ar;
              delete newData.dm;
              delete newData.qr;
              delete newData.sj;
              delete newData.vr;
              delete newData.prep;
              const q = query(collection(this.db, 'submissions'), where('email', '==', newData.email), limit(1));
              const querySnapshot = await getDocs(q);
              let existingId;
              querySnapshot.forEach((document) => {
                existingId = document.id;
              });
              let newDocument;
              if (existingId) {
                await setDoc(doc(this.db, 'submissions', existingId), newData);
              } else {
                newDocument = await addDoc(collection(this.db, 'submissions'), newData);
              }
              // eslint-disable-next-line no-console
              console.log(`Uploaded document ${existingId || newDocument?.id}`);
            })).then(() => {
              this.uploadDialog = false;
              // eslint-disable-next-line no-console
            }).catch(console.error);
          },
        });
      }
    },
  },
  watch: {
    user() {
      if (this.user) void this.fetchRouteConfig();
    },
  },
});
