/* eslint-disable */
import Loader from './Loader.vue';

function Install(Vue, options = {}) {
  const { vuetify } = options;
  delete options.vuetify;
  if (!vuetify) {
    console.warn('Vuetify instance not bound in options for loader module.');
  }
  const Ctor = Vue.extend({ vuetify, ...Loader });
  function createDialogCmp(dialogOptions) {
    const container = document.querySelector('.v-main') || document.body;
    return new Promise((resolve) => {
      let removeFromLoaders;
      const cmp = new Ctor({
        propsData: {
          ...Vue.prototype.$loader.options,
          ...dialogOptions,
          activeLoaders: Vue.prototype.$activeLoaders,
        },
        destroyed: () => {
          // noinspection JSCheckFunctionSignatures
          container.removeChild(cmp.$el);
          resolve(cmp.value);
          removeFromLoaders();
        },
      });
      removeFromLoaders = () => {
        const index = Vue.prototype.$activeLoaders.indexOf(cmp);
        Vue.prototype.$activeLoaders.splice(index, 1);
      };
      Vue.prototype.$activeLoaders.push(cmp);
      container.appendChild(cmp.$mount().$el);
    });
  }

  function show(name, promise, opt = {}) {
    opt.name = name;
    opt.promise = promise;
    return createDialogCmp(opt);
  }

  Vue.prototype.$loader = show;
  Vue.prototype.$activeLoaders = [];
  Vue.prototype.$loader.options = options || {};
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(Install);
}

export default Install;
