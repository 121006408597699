<template>
  <v-container>
    <v-row>
      <v-col class="text-h3">NIE Calculators</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title>Percentile Calculator</v-card-title>
          <v-card-text style="min-height: 75px">
            UCAT Percentiles from raw scores
          </v-card-text>
          <v-card-actions>
            <v-btn block @click="$router.push({name: 'Percentile'})">View</v-btn>
          </v-card-actions>
         </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title>Study Prep Calculator</v-card-title>
          <v-card-text style="min-height: 75px">
            Compare your results to other students who have completed similar levels of preparation.
          </v-card-text>
          <v-card-actions>
            <v-btn block @click="$router.push({name: 'Study Prep'})">View</v-btn>
          </v-card-actions>
         </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style scoped>

</style>
