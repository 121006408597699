<template>
  <v-overlay v-if="!promiseResolved && !dismissed">
    <v-card v-if="linearStyle">
      <v-row dense>
        <v-col class="pa-0 grow">
          <v-card-title>
            {{ name }}...
          </v-card-title>
        </v-col>
        <v-col v-if="dismissible" class="shrink" style="padding-top: 12px; padding-right: 8px;">
          <v-btn icon @click="dismissed=true">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text>
        <v-progress-linear indeterminate/>
      </v-card-text>
    </v-card>
    <v-progress-circular v-else indeterminate/>
  </v-overlay>
  <v-snackbar
      v-else-if="!promiseResolved"
      :value="dismissed"
      :timeout="-1"
      :style="`margin-bottom: ${55*this.snackbarIndex}px`"
  >
    <v-row>
      <v-col class="grow text-body-2">{{ name }}...</v-col>
      <v-col class="shrink"><v-progress-circular indeterminate size="18"/></v-col>
    </v-row>
  </v-snackbar>
  <v-snackbar
      v-else
      v-model="snackbarOpen"
      dismissible
      :color="success ? 'success' : 'error'"
      :style="`margin-bottom: ${55*this.snackbarIndex}px`"
  >
    {{ success ? 'Finished' : 'Ran into an error while' }} {{ name }}.
    <template v-slot:action="{ attrs }">
      <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbarOpen = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    linearStyle: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    promise: {
      type: Promise,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    activeLoaders: {
      type: Array,
      required: true,
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted() {
    this.promise.then(() => {
      this.success = true;
    });

    this.promise.catch(() => {
      this.success = false;
    });

    this.promise.finally(() => {
      this.promiseResolved = true;

      setTimeout(() => {
        // eslint-disable-next-line no-underscore-dangle
        if (!this._isDestroyed) this.destroy();
      }, 10000);
    });
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    snackbarIndex() {
      if (!this.promiseResolved && !this.dismissed) return 0;
      return this.activeLoaders.filter((v) => v.promiseResolved || v.dismissed)
      // eslint-disable-next-line no-underscore-dangle
        .findIndex((v) => v._uid === this._uid);
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      promiseResolved: false,
      snackbarOpen: true,
      success: false,
      dismissed: false,
    };
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    snackbarOpen() {
      this.$destroy();
    },
  },
};
</script>
